// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, getIdTokenResult  } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCtytU4ACOulnzBGtLPownvXXwLoBzKJ-M",
  authDomain: "collaboration-app-fd6dd.firebaseapp.com",
  projectId: "collaboration-app-fd6dd",
  storageBucket: "collaboration-app-fd6dd.appspot.com",
  messagingSenderId: "137125222181",
  appId: "1:137125222181:web:d02baef16afd65e0632ccc",
  measurementId: "G-1XGCGYJ55Z"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth();
const firestore = getFirestore(firebaseApp);

const firebaseUtils = {
  auth,
  firebaseApp,
  firestore,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
};

export default firebaseUtils;

export {
  auth,
  firebaseApp,
  firestore,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getStorage,
  getIdTokenResult
}
