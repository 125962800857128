import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import {themeSetup} from './utils/index';
import { auth } from '@/services/firebase'
import {
    api,
    setApiAuthenticationHeader
} from "@/services/api.js";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/css/tailwind.css'

let app: any;

auth.onAuthStateChanged((user) => {
    if (!app) {
        app = createApp(App);
        app.config.globalProperties.auth = auth;
        app.use(createPinia()).use(router).mount('#app')
    }

    if (user) {
        user.getIdToken().then(function (idToken?: string) {
            // @ts-ignore
            setApiAuthenticationHeader(idToken);
        });
    } else {
        // ...
    }
});
themeSetup(); // setup theme.
