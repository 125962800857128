import axios from 'axios';

const setApiAuthenticationHeader = (accessToken = null) => {
    if (accessToken) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common.authorization;
    }
};

const VAPID_PUBLIC_KEY = "BM8mAVCGslR478IVn_VXo_x1KPsGiDEqdkanDCG9aW_S8cvU1OP7mOx1zb9g9u3Qrc8Q0oi5cRoASHoZRf_WCB8"
const ZOOM_SDK_KEY = "1c29saSRnOVPkquRX6ShQ";
const API_SERVER = "https://us-central1-collaboration-app-fd6dd.cloudfunctions.net/api"

// const API_SERVER = "http://localhost:5000/collaboration-app-fd6dd/us-central1/api"

// basic configurations
axios.defaults.baseURL = API_SERVER;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// create instance
const axiosInstance = axios.create();
// axiosInstance.defaults.headers.common.authorization = `Bearer ${localStorage.getItem('accessToken')}`;

// Axios wrapper to handle error
const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

// axios interceptors to handle token expiration
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {}
);

// API calls.
let api = {
    invite: (data) => axiosWrapper(axiosInstance.post('/ab/invite', data)),
    admin: {
        user: {
            create: (data) => axiosWrapper(axiosInstance.post('/admin/user', data)),
            update: (id, data) => axiosWrapper(axiosInstance.patch(`/admin/user/${id}`, data)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/user/${id}`)),
        },
        room: {
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/room/${id}`)),
        },
        meeting: {
            create: (data) => axiosWrapper(axiosInstance.post('/admin/meeting', data)),
        },
        notification: {
            send: (uid) => axiosWrapper(axiosInstance.get(`admin/sendTestNotification/${uid}`)),
        }
    }
}

export { api, axiosInstance, setApiAuthenticationHeader, API_SERVER, ZOOM_SDK_KEY, VAPID_PUBLIC_KEY };

export default api;
