import { defineStore } from 'pinia'
import { api, setApiAuthenticationHeader } from '@/services/api'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: JSON.parse(localStorage.getItem('user') || '{}'),
    token: localStorage.getItem('accessToken') || null,
    role: localStorage.getItem('role') || 'user'
  }),
  getters: {
    isAuthenticated: (state: any) => !!state.user,
    getUser: (state: any) => state.user,
    getToken: (state: any) => state.token,
    getRole: (state: any) => state.role
  },
  actions: {
    setUser(user: string) {
      this.user = user
    },
    setToken(token: any) {
      this.token = token
      setApiAuthenticationHeader(token)
    },
    setRole(role: any) {
        this.role = role
    },
    logout() {
      this.user = null
      this.token = null
      this.role = 'user'
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('role')
    }
  }
})
