<template>
  <div :class="appTheme">
    <nav
      class="flex items-center justify-between flex-wrap bg-teal-500 p-6"
      v-if="showNav"
    >
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <span class="font-semibold text-xl tracking-tight text-black">App</span>
      </div>
      <div class="flex-grow text-center text-black">
        <span class="text-xl">{{ user?.displayName ?? user?.username }}</span>
      </div>
      <div>
        <button
          @click="signOut"
          class="text-black inline-block text-sm px-4 py-2 leading-none border rounded border-black hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
        >
          Sign Out
        </button>
        <button
            v-if="!notificatoiPermission"
            @click="enableNotificaion"
            class="text-black inline-block text-sm px-4 py-2 leading-none border rounded border-black hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
        >
            Enable Notification
        </button>
      </div>
    </nav>
    <div class="dark:text-white light-text-color bg-white dark:bg-gray-700">
      <div class="" ref="main">
        <router-view />
      </div>
      <hr />
    </div>
  </div>
</template>

<script lang="js">
import ThemeChanger from "@/components/ThemeChanger";

import {
    auth
} from '@/services/firebase.ts'

// fancybox.
import {
    Fancybox
} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import {
    useThemeStore
} from '@/store/ThemeStore';
import {
    useAuthStore
} from '@/store/auth.ts'
import { getUserProfile, updateFCMToken } from '@/utils/user.ts'
import { getMessaging, getToken } from "firebase/messaging";
import { VAPID_PUBLIC_KEY } from '@/services/api'
import Swal from 'sweetalert2'

export default {
    name: 'app',
    components: {
        // ThemeChanger,
    },
    watch: {
        $route(to, from) {
            const base = `App`
            let title = ""
            if (to.path !== "/") this.path = '/'
            else this.path = ""

            if (typeof to.meta.title === 'string')
                title = `${to.meta.title} - ${base}`
            else if (typeof to.meta.title === 'function')
                title = `${to.meta.title(to)} - ${base}`
            else title = base
            document.title = title

            // check if current path is '/' or 'forgot-password' if yes then show nav to false.
            if (to.path === "/" || to.path === "/forgot-password") {
                this.showNav = false;
            } else {
                this.showNav = true;
            }
        },
    },
    data() {
        return {
            top: false,
            mobileMenuOpen: false,
            hidden: false,
            path: "",
            scroll: 0,
            isLogin: false,
            authStore: useAuthStore(),
            user: null,
            showNav: true,
            notificatoiPermission: localStorage.getItem('notificatoiPermission') ? true : false,
        }
    },
    async created() {
        // Fancybox init.
        let fancyboxELems = ["[data-fancybox='default']", "[data-fancybox='certificate']"]
        // for in loop.
        for (let i = 0; i < fancyboxELems.length; i++) {
            Fancybox.bind(fancyboxELems[i], {
                caption: function (fancybox, carousel, slide) {
                    return (
                        slide.caption || ""
                    );
                },
            });
        }

        // back to top button handler.
        // window.addEventListener('scroll', this.handleScroll);
        await this.preload();
    },
    async mounted() {
        await this.preload();
    },
    computed: {
        appTheme() {
            return useThemeStore().theme;
        },
    },
    methods: {
        scrollTop() {
            window.scrollTo(0, 0);
        },
        async preload() {
            this.login = this.authStore.getToken !== null;
            // on change state of login in store.
            // if (!this.login) {
            //     await auth.signOut();
            //     return;
            // }

            this.user = auth.currentUser;
            await this.showPopOverOnStartup();

            let user = this.user;

            let usr = await getUserProfile(user.uid);
            let token = await user.getIdToken()
            this.user.role = usr?.role
            this.user.username = usr?.cname ?? usr.email
            user.cname = usr.cname ?? '';
            user.bname = usr.bname ?? '';
            user.sno = usr.sno ?? '';
            user.room_id = usr.room_id ?? '';
            this.authStore.setUser(user)
            this.authStore.setToken(token);
            
            auth.onIdTokenChanged(async (user) => {
                if (user) {
                    this.user = user;
                    let usr = await getUserProfile(user.uid);
                    let token = await user.getIdToken()
                    this.user.role = usr?.role
                    this.user.username = usr?.username ?? usr.email
                    user.cname = usr.cname ?? '';
                    user.bname = usr.bname ?? '';
                    user.sno = usr.sno ?? '';
                    user.room_id = usr.room_id ?? '';

                    this.authStore.setUser(user)
                    localStorage.setItem('accessToken', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    this.authStore.setToken(token);
                } else {
                    this.user = null;
                    this.authStore.setUser(null)
                    this.authStore.setToken(null);
                }
            });
        },
        async enableNotificaion() {
            // Notofication permission.
            const messaging = getMessaging();
            Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                // Get the token if permission is granted
                getToken(messaging, { vapidKey: VAPID_PUBLIC_KEY })
                    .then(async (currentToken) => {
                        if (currentToken) {
                            console.log ('currentToken', currentToken)
                            await updateFCMToken(this.user.uid, currentToken)
                            localStorage.setItem('notificatoiPermission', true);
                            this.notificatoiPermission = true;
                            Swal.fire({
                                icon: 'success',
                                title: 'Notification permission granted!',
                                text: 'Notification permission granted!',
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'No register token!',
                                text: 'No registration token available. Request permission to generate one.',
                            })
                        }
                    })
                    .catch((err) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error occurred! 1',
                            text: 'An error occurred while retrieving token. ' + err,
                        })
                        console.log ('An error occurred while retrieving token. ', err)
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to get permission to notify.',
                        text: 'Unable to get permission to notify.',
                    })
                    console.log('Unable to get permission to notify.');
                }
            });
        },
        async showPopOverOnStartup() {
            // check if do_not_show exists in localstorage.
            if (localStorage.getItem('do_not_show')) {
                return;
            }
            // show pop over.
            Swal.fire({
                title: 'Enable Notification',
                text: 'Please turn on your browser notifications, For Android, go to settings, click Apps-> App Management->Chrome->Manage Notifications->Allow notifications, turn it ON.',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Do not show again`,
                denyButtonText: `Close`,
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.setItem('do_not_show', true);
                }
            })
        },
        async signOut() {
            await auth.signOut();
            this.authStore.setUser(null)
            this.authStore.setToken(null);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user');
            this.$router.push('/')
        },
    },
}
</script>
