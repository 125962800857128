/**
 * Function to set the theme.
 * @since v1.0.0
 * @returns null
 */
const themeSetup = () => {
    const appTheme = localStorage.getItem('theme') || 'light';

      if (
        appTheme === 'dark' &&
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.contains('app-theme')
      ) {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-secondary-light');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-primary-dark');
      } else {
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.remove('bg-primary-dark');
        // @ts-ignore: Object is possibly 'null'.
        document.querySelector('body').classList.add('bg-secondary-light');
      }
}

const clearCache = async () => {
  // clear local storage all data.
  await localStorage.clear();

  // clear all cache data.
  await caches.keys().then((keyList: any) =>
    Promise.all(
      keyList.map((key: any) => {
          return caches.delete(key);
      })
    )
  )
}

const formatTimestamp = (timestamp: any) => {
  // Convert the Firestore timestamp to a JavaScript Date object
  const date = timestamp.toDate();

  // Format the date
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are 0-based in JavaScript
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Return the formatted date
  return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
}

const getTimeFromDate = (dateString: any) => {
  // if null, empty or undefined return N/A.
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toTimeString().split(' ')[0];
}

export {
    themeSetup,
    clearCache,
    formatTimestamp,
    getTimeFromDate
}
