import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Auth/LoginView.vue'),
    meta: {
      title: "Login",
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/meeting/:id',
    name: 'MeetingView',
    component: () => import('../views/MeetingView.vue'),
    meta: {
      title: "Meeting",
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/chat/',
    name: 'UserChat',
    component: () => import('../views/User/ChatView.vue'),
    meta: {
      title: "Chat",
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import('../views/AdminView.vue'),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/admin/chat/:id',
    name: 'AdminChat',
    component: () => import('../views/Admin/ChatView.vue'),
    meta: {
      title: "Chat",
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: () => import('../views/site/404View.vue'),
    meta: {
      title: "404 Not Found"
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/Auth/ForgotPassword.vue'),
    meta: {
      title: "Forgot Password",
      requiresAuth: false,
      requiresAdmin: false
    }
  }
];

export default routes;
