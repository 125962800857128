import { createRouter, createWebHistory } from 'vue-router'
import { auth, getIdTokenResult } from '@/services/firebase'

import routes from './routes'

// Nprogress
import Nprogress from 'nprogress'
// Nprogress CSS
import 'nprogress/nprogress.css';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to: any, from: any, savedPosition: any) {
    if (to.hash) {
      const elem = document.getElementById(to.hash.replace("#", ""))
      if (elem)
        window.scrollTo(0, elem.offsetTop);
    } else {
      // @ts-ignore
      document.getElementById('app').scrollIntoView();
    }
  }
})

// Firebase guard middleware
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth) {
    const claims = currentUser ? await getIdTokenResult(currentUser) : null;
    const role = claims?.claims?.role;
    console.log("claim", role);
    if (role) {
      if (role === 'admin' || role === 'superadmin') {
        console.log("admin");
        // if path does not contain /admin, redirect to /admin.
        if (!to.path.includes('/admin')) {
        console.log("redirecting...");
          next('/admin');
        }
      } else {
        // if path does not contain /dashboard, redirect to /dashboard.
        if (!to.path.includes('/dashboard')) {
          next('/dashboard');
        }
      }
    } else {
      if (!to.path.includes('/dashboard')) {
        next('/dashboard');
      }
    }
  }

  if (requiresAuth && !currentUser) {
    next('/');
  } else if (!requiresAuth && currentUser) {
    next('/dashboard');
  } else {
    next();
  }
});

// Nprogress middleware
router.beforeEach((to, from, next) => {
  Nprogress.start();
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  Nprogress.done();
});

export default router;
