import {
    doc, getDoc, updateDoc, serverTimestamp
} from 'firebase/firestore'
import {
    firestore,
} from '@/services/firebase'

const getUserProfile = async (uid: string) => {
    const userRef :any = doc(firestore, 'users', uid);
    const userDoc :any = await getDoc(userRef);

    if (userDoc.exists()) {
        return userDoc.data();
    }
    return null;
}

const updateLastLogin = async (uid: string) => {
    const userRef :any = doc(firestore, 'users', uid);
    await updateDoc(userRef, {
        lastLogin: serverTimestamp()
    });
}

const updateFCMToken = async (uid: string, fcmToken: string) => {
    let tokens = [];
    let profile = await getUserProfile(uid);
    if (profile.fcmToken) {
        tokens = profile.fcmToken ?? [];
    }
    // check if the token already exists in the array.
    if (tokens.includes(fcmToken)) {
        return;
    }
    tokens.push(fcmToken);
    const userRef :any = doc(firestore, 'users', uid);
    await updateDoc(userRef, {
        fcmToken: tokens
    });
}

export {
    getUserProfile,
    updateLastLogin,
    updateFCMToken,
}
